<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Row-->
      <div class="row gx-5 gx-xl-10">
        <!--begin::Col-->
        <div class="col-xxl-8 mb-5 mb-xl-10">
          <div class="card">
            <div class="card-body">
              <img
                src="/assets/media/illustrations/map-tni.jpg"
                class="map-tni w-100 rounded"
                onclick="$(this).toggleClass('d-none');$('.map-tni-detail').toggleClass('d-none')"
              />
              <img
                src="/assets/media/illustrations/map-tni-detail.jpg"
                class="map-tni-detail w-100 rounded d-none"
                onclick="$(this).toggleClass('d-none');$('.map-tni').toggleClass('d-none')"
              />
            </div>
          </div>
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-xxl-4 mb-5 mb-xl-10">
          <div
            class="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0"
            style="background-color: #065514"
          >
            <div class="card-body d-grid align-items-center">
              <div class="row">
                <div class="col-12">
                  <div class="d-flex flex-stack">
                    <div class="symbol symbol-70px me-3">
                      <img
                        src="/assets/media/logos/tni-logo.png"
                        class="h-100 img-fluid"
                      />
                    </div>
                    <div class="d-flex flex-column m-0 text-end">
                      <span class="fs-2hx fw-bold text-white me-2 lh-1 ls-n2"
                        >585.345</span
                      >
                      <span class="text-white opacity-50 pt-1 fw-semibold fs-6"
                        >Aggota TNI</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator my-3"></div>
              <div class="row">
                <div class="col-12">
                  <div class="d-flex flex-stack">
                    <div class="symbol symbol-70px me-3">
                      <img
                        src="/assets/media/logos/TNI-AD.png"
                        class="h-100 img-fluid"
                      />
                    </div>
                    <div class="d-flex flex-column m-0 text-end">
                      <span class="fs-2x fw-bold text-white me-2 lh-1 ls-n2"
                        >470.446</span
                      >
                      <span class="text-white opacity-50 pt-1 fw-semibold fs-6"
                        >Angkatan Darat</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator separator-dashed my-3"></div>
              <div class="row">
                <div class="col-12">
                  <div class="d-flex flex-stack">
                    <div class="symbol symbol-70px me-3">
                      <img
                        src="/assets/media/logos/TNI-AL.png"
                        class="h-100 img-fluid"
                      />
                    </div>
                    <div class="d-flex flex-column m-0 text-end">
                      <span class="fs-2x fw-bold text-white me-2 lh-1 ls-n2"
                        >76.034</span
                      >
                      <span class="text-white opacity-50 pt-1 fw-semibold fs-6"
                        >Angkatan Laut</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator separator-dashed my-3"></div>
              <div class="row">
                <div class="col-12">
                  <div class="d-flex flex-stack">
                    <div class="symbol symbol-70px me-3">
                      <img
                        src="/assets/media/logos/TNI-AU.png"
                        class="h-100 img-fluid"
                      />
                    </div>
                    <div class="d-flex flex-column m-0 text-end">
                      <span class="fs-2x fw-bold text-white me-2 lh-1 ls-n2"
                        >38.865</span
                      >
                      <span class="text-white opacity-50 pt-1 fw-semibold fs-6"
                        >Angkatan Udara</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator separator-dashed my-3"></div>
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row gx-5 gx-xl-10">
        <!--begin::Col-->
        <div class="col-xxl-8 mb-5 mb-xl-10">
          <!--begin::Chart widget 27-->
          <div class="card card-flush h-xl-100">
            <div class="card-header border-0 pt-5">
              <!--begin::Title-->
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bold fs-3 mb-1"
                  >Permintaan Cetak Kartu Tahun 2023</span
                >
              </h3>
              <!--end::Title-->
            </div>
            <!--begin::Body-->
            <div class="card-body pt-0 pb-1">
              <img
                src="/assets/media/illustrations/maps.jpg"
                class="w-100 rounded"
              />
            </div>
            <!--end::Body-->
          </div>
          <!--end::Chart widget 27-->
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-xxl-4 mb-5 mb-xl-10">
          <!--begin::Chart widget 27-->
          <div class="card card-flush h-xl-100">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <!--begin::Title-->
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bold fs-3 mb-1"
                  >Jumlah Cetak Kartu Tahun 2023</span
                >
                <span class="text-muted fw-semibold fs-7"
                  >Lebih dari 40 Pengajuan</span
                >
              </h3>
              <!--end::Title-->
              <!--begin::Toolbar-->
              <div class="card-toolbar">
                <!--begin::Menu-->
                <button
                  type="button"
                  class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i class="ki-outline ki-category fs-6"></i>
                </button>
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                  data-kt-menu="true"
                  id="kt_menu_64b77d12f3e55"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bold">Filter</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Menu separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Menu separator-->
                  <!--begin::Form-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Status:</label>
                      <!--end::Label-->
                      <!--begin::Input-->
                      <div>
                        <select
                          class="form-select form-select-solid"
                          multiple="multiple"
                          data-kt-select2="true"
                          data-close-on-select="false"
                          data-placeholder="Select option"
                          data-dropdown-parent="#kt_menu_64b77d12f3e55"
                          data-allow-clear="true"
                        >
                          <option></option>
                          <option value="1">TNI AL</option>
                          <option value="2">TNI AD</option>
                          <option value="3">TNI AU</option>
                        </select>
                      </div>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Kategori : </label>
                      <!--end::Label-->
                      <!--begin::Options-->
                      <div class="d-flex">
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid me-5"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                          />
                          <span class="form-check-label">Tantama</span>
                        </label>
                        <!--end::Options-->
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid me-5"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="2"
                          />
                          <span class="form-check-label">Bintara</span>
                        </label>
                        <!--end::Options-->
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="3"
                            checked="checked"
                          />
                          <span class="form-check-label">Perwira</span>
                        </label>
                        <!--end::Options-->
                      </div>
                      <!--end::Options-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-sm btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Menu 1-->
                <!--end::Menu-->
              </div>
              <!--end::Toolbar-->
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::Chart-->
              <div id="chart_pie"></div>
              <!--end::Chart-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Chart widget 27-->
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-xxl-4 mb-5 mb-xl-10">
          <!--begin::Chart widget 28-->
          <div class="card card-flush h-xl-100">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bold fs-3 mb-1"
                  >Distribusi KTA Tahun 2023</span
                >
              </h3>
              <!--begin::Toolbar-->
              <div class="card-toolbar d-none" data-kt-buttons="true">
                <a
                  class="btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1"
                  id="kt_charts_widget_2_month_btn"
                  >Per Bulan</a
                >
                <a
                  class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4"
                  id="kt_charts_widget_2_week_btn"
                  >Per Minggu</a
                >
              </div>
              <!--end::Toolbar-->
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body p-0">
              <!--begin::Chart-->
              <div id="chartdiv"></div>
              <!--end::Chart-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Chart widget 28-->
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-xxl-4 mb-5 mb-xl-10">
          <!--begin::List widget 9-->
          <div class="card card-flush h-xl-100">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <!--begin::Title-->
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bold fs-3 mb-1"
                  >Keseluruhan Perwilayah Kodam TNI</span
                >
              </h3>
              <!--end::Title-->
              <!--begin::Toolbar-->
              <div class="card-toolbar">
                <!--begin::Menu-->
                <button
                  type="button"
                  class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i class="ki-outline ki-category fs-6"></i>
                </button>
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                  data-kt-menu="true"
                  id="kt_menu_64b77d12f3e551"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bold">Filter</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Menu separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Menu separator-->
                  <!--begin::Form-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Kodam :</label>
                      <!--end::Label-->
                      <!--begin::Input-->
                      <div>
                        <select
                          class="form-select form-select-solid"
                          multiple="multiple"
                          data-kt-select2="true"
                          data-close-on-select="false"
                          data-placeholder="Select option"
                          data-dropdown-parent="#kt_menu_64b77d12f3e551"
                          data-allow-clear="true"
                        >
                          <option></option>
                          <option value="1">Approved</option>
                          <option value="2">Pending</option>
                          <option value="2">In Process</option>
                          <option value="2">Rejected</option>
                        </select>
                      </div>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-sm btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Menu 1-->
                <!--end::Menu-->
              </div>
              <!--end::Toolbar-->
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body p-0">
              <!--begin::Chart-->
              <div id="chart_kodam"></div>
              <!--end::Chart-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::List widget 9-->
        </div>
        <!--end::Col-->

        <div class="col-xxl-4 mb-5 mb-xl-10">
          <div class="card card-flush h-xl-100">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bold fs-3 mb-1"
                  >Transaksi Dengan Kartu Tahun 2023</span
                >
              </h3>
              <!--begin::Toolbar-->
              <div class="card-toolbar d-none" data-kt-buttons="true">
                <a
                  class="btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1"
                  id="kt_charts_widget_3_year_btn"
                  >Per Bulan</a
                >
                <a
                  class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1"
                  id="kt_charts_widget_3_month_btn"
                  >Per Minggu</a
                >
              </div>
              <!--end::Toolbar-->
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::Chart-->
              <div id="chart"></div>
              <!--end::Chart-->
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
      <!--end::Row-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>
<script></script>
